/*******************************
             Headers
*******************************/

h1,
h2,
h3,
h4,
h5 {
  font-family: $display-font;
  line-height: 1.28571429em;
  margin: calc(2rem - 0.14285714em) 0em 1rem;
  font-weight: bold;
  padding: 0em;
}

h1 {
  min-height: 1rem;
  font-size: 2rem;
}

h2 {
  font-size: 1.71428571rem;
}

h3 {
  font-size: 1.28571429rem;
}

h4 {
  font-size: 1.07142857rem;
}

h5 {
  font-size: 1rem;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child {
  margin-top: 0em;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child {
  margin-bottom: 0em;
}

/*******************************
             Text
*******************************/

p {
  font-family: $body-font;
  margin: 0em 0em 1em;
  line-height: 1.4285em;
  &:first-child {
    margin-top: 0em;
  }
  &:last-child {
    margin-bottom: 0em;
  }
}

/*-------------------
        Links
--------------------*/

a {
  font-family: $body-font;
  color: $default-link-color;
  text-decoration: none;
  &:hover {
    color: var(--default-link-hover-color);
    text-decoration: none;
  }
}
