.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.message {
  margin: auto;
  max-width: 720px;
  line-height: 2;
}

.instructions {
  padding: 0 40px;
}

.link {
  display: block;
  font-weight: bold;
  margin: 20px 0;
}

.form {
  background-color: #f3f3f3;
  padding: 32px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  fieldset {
    display: flex;
    flex-direction: column;
    gap: 20px;

    label {
      display: block;
      margin-bottom: 5px;
    }

    input,
    textarea {
      width: 100%;
      padding: 12px;
      border-radius: 4px;
      border: 1px solid #999;
      font-family: arial;
    }

    textarea {
      height: 200px;
    }
  }
}
