//////////////////////////////////
//     Application Settings     //
//////////////////////////////////

// MEDIA QUERIES
$media-small: 30em; // 480px
$media-medium: 48em; // 768px
$media-large: 64em; //1024px
$media-xlarge: 90em; //1440px

$small-range: 0 30em; // 0px to 480px
$medium-range: 30.063em 48em; // 481px to 768px
$large-range: 48.063em 64em; // 769px to 1024px
$xLarge-range: 64.063em 90em; //1025px to 1440px
$xxLarge-range: 90.063em 999em; //1441 px and up

// REM BASE (font-size on html element)
$rem-base: 16px;
