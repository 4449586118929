@keyframes appear {
  0% {
    opacity: 0.01;
  }
  100% {
    opacity: 1;
  }
}

.visually-hidden {
  /* !important is used here to ensure that wherever in the hierarchy, those
     elements will stay hidden. For example, overriding the checkbox and
     radio buttons with custom components.
  */
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  margin-left: -1px;
}

.modal-wrapper {
  background-color: rgba(#505554, 0.85);
  animation: appear 200ms linear;
}

.modal {
  width: 100%;
}

.modal__button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px !important;
  height: 44px !important;
  width: 44px !important;
  background: transparent !important;
  color: #505554 !important;
  font-size: 2rem !important;
}

.modal__content {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  background: var(--white);

  max-width: 600px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);

  // @include min-screen($media-small) {
  //   height: auto;
  //   margin: 0 auto;
  //   border-radius: 3px;
  // }

  // &.modal__content--multicolumn {
  //   @include min-screen($media-small) {
  //     max-width: 75%;
  //   }
  // }
}

.modal__content--fullscreen {
  height: 75vh;
  max-width: 75%;
}

.modal__column {
  flex: 1 1 100%;
  min-width: 320px;

  > *:not(img) {
    padding: 24px;

    // @include min-screen($media-medium) {
    //   padding: 48px 24px 24px;
    // }
  }

  + .modal__column,
  &:only-of-type {
    max-height: 75vh;
    overflow: auto;
  }

  // @include min-screen($media-medium) {
  //   flex: 1 1 50%;
  // }

  &:first-of-type:not(:only-of-type) {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 220px;
    overflow: hidden;

    // @include min-screen($media-medium) {
    //   max-height: none;
    // }

    // > img {
    //   min-width: 100%;
    //   min-height: 100%;
    //   object-fit: cover;
    //   max-height: 35vh;
    // }
  }
}
