@import "./styles/dependencies/reset.scss";
@import "./styles/applicationSettings.scss";
@import "./styles/functions.scss";
@import "./styles/dependencies/colors.scss";
@import "./styles/dependencies/fonts.scss";
@import "./styles/dependencies/mediaQueries.scss";
@import "./styles/dependencies/timingFunctions.scss";
@import "./styles/dependencies/typography.scss";
@import "./styles/base.scss";
@import "./styles/home.scss";

// @import './styles/elements/icon.scss';
// @import './styles/elements/image.scss';
// @import './styles/elements/input.scss';
// @import './styles/elements/item.scss';
// @import './styles/elements/label.scss';
// @import './styles/elements/list.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
