.home {
  min-height: 100vh;
  width: 100%;
  max-width: 1300px;
  margin: auto;
  @include bodyFont();
}

.app-header {
  background: var(--white);
  padding: 14px 20px;
  margin-bottom: 20px;
  height: 56px;
}
