html {
  font-size: $rem-base;
}

html,
body {
  width: 100%;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;

  &:before,
  &:after {
    box-sizing: inherit;
  }
}

.app {
  min-height: 100vh;
  padding-bottom: 20px;
}

.app__container {
  height: inherit;
}
