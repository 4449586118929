////////////////////////////////////////
//      Font-Family Definitions       //
////////////////////////////////////////

$helvetica: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;

///////////////////////////////
//      Font Variables       //
///////////////////////////////
$display-font: $helvetica;
$display-font--bold: $helvetica;
$body-font: $helvetica;

:root {
  --display-font: $display-font;
  --display-font--bold: $display-font--bold;
  --body-font: $body-font;
}

////////////////////////////
//      Font Mixins       //
////////////////////////////
@mixin displayFontBold($color: $display-color) {
  font-family: $display-font--bold;
  font-weight: 500;
  color: $color;
}

@mixin displayFont($color:$display-color) {
  font-family: $display-font;
  font-weight: 400;
  color: $color;
}

@mixin bodyFont() {
  font-family: $body-font;
  font-weight: 400;
  color: var(--default-font-color);
}
